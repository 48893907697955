header {
	.sticky.is-anchored #header-2 ul li:first-child a{
		color:white;
	}
	#header-1{
		background-color:white;
		padding-top:30px;
		padding-bottom: 38px;
		#logo{
			display: block;
		}
		#logo-scroll{
			display: none;
		}
		.language{
			margin-bottom: 30px;
			text-align: right;
			display: none;
			a{
				margin:0 12px;
				font-size: 20px;
				
				&:hover{
					color: #646464;
				}
				&:last-child{
					margin-right:0px;
				}
			}
			.active{
				color: #646464;
			}
		}
		p{
			text-align: right;
			color: #49a9c4;
			font-size: 13px;
			font-weight: 400;
		}
	}

	#header-2{
		background-color: $dark-gray;
		.menu{
			li{
				a{
					text-transform: uppercase;
					font-size: 20px;
					font-weight: 400;
					padding: 15px 23px;
					font-family: 'Avenir', sans-serif;
				}
				a.is-active{
					color:white;
				}
				&:first-child a{
					padding-left: 0;
				}
			}
		}
	}
	.is-stuck {
		#header-1 {
			background: #1e1e1e;
			transition: 0.2s;
			padding-top:15px;
			padding-bottom: 15px;
			#logo{
				display: none;
			}
			#logo-scroll{
				display: block;
				height: 30px;
			}
			.columns:last-of-type {
				display: none;
			}
		}
	}
}
#mobile-header{
	z-index:99;
	background:#1e1e1e;
	.title{
		.mobile-logo{
			max-width:320px;
			padding:15px 25px;
		}
		.btn-bar{
			background:#464646;
			border-top:2px solid white;
			border-bottom:2px solid white;
			text-align: center;
			padding:9px 0;
			a{
				width:100%;
				height: 16px;
				display:block;
				background:url("../../assets/img/mobile-open.png");
				background-repeat:no-repeat;
				background-size:contain;
				background-position:center;
			}
			a.menu-open.open{
				background:url("../../assets/img/mobile-close.png");
				background-repeat:no-repeat;
				background-size:contain;
				background-position:center;
			}
		}
	}
	.mobile-drop{
		display:none;
		.language{
			background:#f5f5f5;
			padding:10px 0;
			border-bottom:1px;
			font-size:18px;
			.active{
				color:#464646;
			}
		}
		position:absolute;
		background:#d2d2d2;
		max-width:320px;
		margin:auto;
		left:0;
		right:0;
		text-align:center;
		text-transform: uppercase;
		nav{
			ul{
				display:block;
				li{
					border-top:1px solid white;
					border-bottom:1px solid white;
					padding:4px 0;
					a{
						font-weight:300;
						font-size:18px;
						&.is-active{
							color:#464646;
						}
					}
				}
			}
		}
		.close-area{
			background:#464646;
			text-align: center;
			border-top:1px solid white;
			border-bottom:2px solid white;
			padding:9px 0;
			a{
				height:16px;
				width:100%;
				display:block;
			}
			a.menu-open.open{
				background:url("../../assets/img/mobile-close.png");
				background-repeat:no-repeat;
				background-size:contain;
				background-position:center;
			}
		}
	}
}