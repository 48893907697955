section#company{
	
	background-position:right;
	background-repeat:no-repeat;
	@include breakpoint(small) {
		padding: 50px 0 60px;
	}
	@include breakpoint(medium) {
		padding: 90px 0 120px;
		background-image: url('../img/compass.png');
		//background-size:90%;
	}
	h2{
		margin-bottom:20px;
	}
}

#full-width-image{
	background-image:url('../img/full-width-image.jpg');
	background-size:cover;
	background-position: center center;
	height:250px;
	width:100%;
	@include breakpoint(medium) {
			height:450px;
	}
}