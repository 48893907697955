#full-width-image-3{
	background-image:url('../img/full-width-image-3.jpg');
	background-position:center center;
	background-repeat:no-repeat;
	background-size:cover;
	height:200px;
	@include breakpoint(medium){
		height:450px;
	}
}

section#impressum{
	@include breakpoint(medium){
		background-image:url('../img/compass-3.png');
		background-position: 100% 15%;
		background-repeat:no-repeat;
	}
	
	margin-top:110px;
	
	h4{
		margin-top: 55px;
		margin-bottom:35px;
	}
	p{
		margin-bottom:0.5rem;
	}
}

