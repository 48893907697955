#teaser{
	background-image: url('../img/bg-teaser.jpg');
	@include breakpoint(small){
		min-height: 200px;
		background-position: 95% 50%;
		background-size: 200%;
	}
	@include breakpoint(medium){
		
		background-position:center;
		background-repeat:no-repeat;
		background-size:cover;
	}
	color:white;
	h2{
		color: white;
		margin-top: 86px;
		margin-bottom: 30px;
	}
	h4{
		margin-top: 99px;
	}
	p{
		color:white;
	}
	.orbit{
		.orbit-wrapper{
					
			.orbit-container{
				height: auto;
				.orbit-slide{
					p{
						margin-top:10px;
						height:auto;
						width: 100%;
					}
				}
			}
		}
		.orbit-bullets{
			margin-top: 10px;
			button{
				border-radius:0;
				height: 5px;
			}
		}
	}
	.scroll-bottom{
		text-align: center;
		transform: rotate(180deg);
		margin:100px 0;
	}
}
#teaser-mobile{
	color:white;
	h2{
		color: white;
		margin-top: 86px;
		margin-bottom: 30px;
	}
	h4{
		margin-top: 30px;
	}
	.orbit{
		.orbit-wrapper{
					
			.orbit-container{
				height: auto;
				.orbit-slide{
					p{
						margin-top:10px;
						height:auto;
						width: 100%;
						color:white;
					}
				}
			}
		}
		.orbit-bullets{
			margin-top: 10px;
			button{
				border-radius:0;
				height: 5px;
			}
		}
	}
}