#contact{
	position:relative;
	overflow:hidden;
	.contact-area{
		position:relative;
		background-color:#646464;
		@include breakpoint(small){
			padding-top:25px;
			padding-bottom:40px;
		}
		@include breakpoint(medium){
			padding-top:107px;
			padding-bottom:89px;
		}	
		h2{
			color: white;
		}
		p{
			color: white;
		}
		h3{
			color: white;
			margin-top:30px;
		}
		.compass-two{
			position:absolute;
			top:15px;
			margin:auto;
			left:900px;
			right:0px;
		}
	}
}

#full-width-image-4{
	background-image:url('../img/full-width-image-4.jpg');
	background-size:cover;
	background-position: center center;
	height:150px;
	width:100%;
	@include breakpoint(medium){
		height:450px;
	}
}

#office{
	h4{
		margin-bottom:0px;
		line-height:1.2;
	}
	.tabs-title > a:hover {
	    background: transparent;
	    color: #1468a0;
	}
	text-align:center;
	
	@include breakpoint(small){
		padding-top:30px;
	}
	@include breakpoint(medium){
		padding-top:125px;
	}
	color:#49a9c4;
	.active{
		color:#646464;
		img{
			display: inline-block;
		}
	}
	
	.buro{
		cursor: pointer;
		&:hover{
			color:#646464;
			img{
				display: inline-block;
			}
		}
	}
	.tabs{
		width: 100%;
		border: none;
		.tabs-title{
			width: 50%;
			&:focus{
				outline: none;
				background-color:transparent;
			}
			a{
				color:#49a9c4;
				&:hover{
					color: #646464;
					img{
						display: inline-block;
					}
				}
				img{
					display: none;
				}
				&:focus{
					outline: none;
					background-color:transparent;
				}
			}
			a[aria-selected='true']{
				background-color:transparent;
				color: #646464;
				img{
					display: inline-block;
				}
			}
		}
	}

	.tabs-content{
		border:none;
		height: 300px;
		@include breakpoint(medium){
			height: 450px;
		}
		//position:absolute;
		.tabs-panel{
			height: 100%;
			padding:0;
			iframe{
				width: 100%;
				height: 100%;
				//position:relative;
			}
		}
		
	}
}
@media screen and (max-width:1023px){
	.compass-two{
		display:none;
	}
}
// #map{
// 	height:450px;
// 	position: relative;
// 	margin-top:20px;
// 	#frankfurt{
// 		width: 100%;
// 		height: 100%;
// 		position:absolute;
// 	}
// 	#hamburg{
// 		position: absolute;
// 		width: 100%;
// 		height: 100%;
// 	}
// }