@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@font-face {
    font-family:"Avenir";
    src: url("../fonts/Avenir.ttc") 
}
@font-face {
	font-family: "Avenir Black";
	src: url('../fonts/Avenir-Black.ttf')
}
@font-face {
    font-family:"Sketch";
    src: url("../fonts/Sketch.ttf") 
}

@include foundation-global-styles;
// @include foundation-grid;
 @include foundation-flex-grid;
//
//@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

@import 'components/global';
@import 'components/header';
@import 'components/teaser';
@import 'components/company';
@import 'components/investments';
@import 'components/partner';
@import 'components/aboutus';
@import 'components/team';
@import 'components/contact';
@import 'components/footer';
@import 'components/impressum';
