section.partner{
	background-color:#f5f5f5;
	.back{
		font-size: 22px;
		font-family: 'Avenir',sans-serif;
		font-weight: 600;
		margin-bottom:35px;
		margin-top:35px;
		text-transform: uppercase;
		a{
			&:before{
				content: '';
				border-bottom:4px solid;
				border-left:4px solid;
				transform: rotate(45deg);
				width: 20px;
				height: 20px;
				display: inline-block;
				margin-right: 20px;
				padding-top:5px;
			}
		}
	}
	.orbit{
		.orbit-wrapper{
				&:focus{
					outline: none;
				}
			.orbit-container{
				&:focus{
					outline: none;
				}
				.orbit-slide{
					color:#646464;
					h2{
						color:#646464;
					}
				}
			}
			.orbit-controls{
				.orbit-previous, .orbit-next{
					background-color:transparent;
					color: #49a9c4;
					font-size: 120px;
					line-height: 1;
					cursor: pointer;
					&:hover{
						background-color:transparent;
					}
					&:focus{
						background-color:transparent;
					}
					&:active{
						background-color:transparent;
					}
				}
				.orbit-previous{
					left:-300px;
				}
				.orbit-next{
					right: -300px;
				}
			}
		}
		.orbit-bullets{
			margin-top: 10px;
			button{
				border-radius:0;
				height: 5px;
				width: 53px;
				background-color: #49a9c4;
			}
			button.is-active{
				background-color:#646464;
			}
		}
	}
}