a {
	color: #49a9c4;
	&.download {
		&:after {
			display: inline-block;
			margin-left: 8px;
			content: '\00BB';
			transform: rotate(90deg);
		}
	}
	&:hover {
		color: #49a9c4;
	}
}
a:focus {
    color: #49a9c4;
}
p{
	font-size:16px;
	line-height:1.4;
	font-family: Arial, sans-serif;
}

h2{
	text-transform:uppercase;
	color: #bebebe;
	line-height: 1.0;
	font-family: 'Avenir Black', sans-serif;
	font-weight:400;
}

h3{
	font-family: 'Sketch', sans-serif;
}

h4{
	text-transform:uppercase;
	font-family: 'Avenir Black', sans-serif;
	font-weight:400;
}

.mobile-header{
	h2{
		color:white;
		margin-bottom:0 !important;
		padding:25px 0 5px;
	}
	background-color:#464646;
	color:white;
	text-transform:uppercase;
	padding: 15px;
}

.pfeil-dl{
	font-size:20px;
}
.column, .columns {
    padding-right: 15px;
    padding-left: 15px;
}
@media screen and (max-width:40em){
	section#aboutus .info h4 {
		margin-top:15px;
	}
}