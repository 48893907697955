#investments{
	margin-top:69px;
	h2{
		margin-bottom:20px;
	}
}
#investment-header{
	margin-top:69px;
}
.investment-tabs{
	background-color: white;
	.asset{
		h4{
			color: white;
			
		}
	}
	.beteiligungen{
		background-color:white;
		h4{
			color: #49a9c4;

		}
	}
}

.investment-content{
	background-color:#f5f5f5;
	
	@include breakpoint(small){
		padding:38px 0 20px 0;
	}
	@include breakpoint(medium){
		padding:38px 0 68px 0;
	}
	h4{
		margin-top: 45px;
	}
	img{
		margin-bottom:15px;
	}
	.inv-box img{
		cursor: pointer;
	}
}

#managmentteam{
	background-color:#646464;
	padding: 30px 0;
	img{
		//margin: 30px 15px;
	}
	@include breakpoint(small){
		margin-bottom: 50px;
	}
	@include breakpoint(medium){
		margin-bottom: 0;
	}
}
#investment-content{
	.tabs-content{
		border:none;
	}

	.tabs{
		border:none;
		@include breakpoint(medium){
		}
		#panel1-label{
			text-align:left;
			@include breakpoint(medium) {
				text-align:right;
			}
		}
		.tabs-title{
			width: 50%;
			@include breakpoint(small){
				width: 100%;
			}
			@include breakpoint(medium){
				width: 50%;
			}
			&:focus{
				outline: none;
				background-color:transparent;
			}
			a{
				padding-top:16px;
				padding-bottom:12px;
				z-index:1;
				height:51px;
				position:relative;
				width: 100%;
				font-family: 'Avenir Black', sans-serif;
				@include breakpoint(small){
					font-size: 16px;
					padding-left: 15px;
				}
				@include breakpoint(medium){
					font-size: 22px;
					padding-left: 1rem;
				}
				color: #49a9c4;
				text-transform: uppercase;
				font-weight: 800;
				&:focus{
					outline: none;
					background-color:transparent;
				}
			}
			a#panel2-label[aria-selected='true']{
				&:after{
					display:block;
					content:"";
					top:0;
					left:-1000px;
					background-color:#646464;
					position:absolute;
					height:51px;
					width:1000px;
					z-index: 0;
				}
			}
			a#panel1-label[aria-selected='true']{
				&:after{
					display:block;
					content:"";
					top:0;
					right:-1000px;
					background-color:#646464;
					position:absolute;
					height:51px;
					width:1000px;
					z-index: 0;
				}
			}
			a[aria-selected='true']{
				background-color: #646464;
				color: white;

			}

		}
	}
}
@media screen and (max-width:1023px){
	.fullWidth.border{
		display:none;
	}
}
#managementteam{
	padding-top:60px;
	h4{
		color:#646464;
	}
	
}

#accordion{
	.small-12{
		padding-left:0;
		padding-right:0;
	}
}

.accordion{
	background:transparent;
	
	.accordion-item{
		&:last-child:not(.is-active) > .accordion-title{
			border:none;
		}
		.accordion-title{
			border:none;
			text-transform: uppercase;
			color: #49a9c4;
			font-size: 18px;
			font-family: 'Avenir', sans-serif;
			font-weight:800;
			padding-bottom:15px;
			padding-bottom:10px;
			&:hover{
				background-color: transparent;
			}
			&:focus{
				background-color: transparent;
			}
			
		}
	}
	.accordion-item.is-active{
		.accordion-title{
			color: #646464;
		}
	}
	.accordion-content{
		background:transparent;
		border:none;
		padding-top: 0;
	}
	
}