section#aboutus {
	background-image: url('../img/clock.png');
	background-repeat:no-repeat;
	background-size:100%;
	background-position:top right;
	.daten{
		.info{
			p{
				height:160px;
			}
		}
	}
	.img-text{
		margin-bottom: 25px;
	}
	.profile{
		@include breakpoint(small){
			margin-top:39px;
		}
		@include breakpoint(medium){
			margin-top:0;
		}
		h4{
			
			margin-bottom:0;
			@include breakpoint(small){
				margin-top:30px;
			}
			@include breakpoint(medium){
				margin-top: 30px;
			}
		}
		a.download {
			&:after {
				float: right;
				margin-right: 15px;
				font-size: 19px
			}
		}
		.daten{
			@include breakpoint(small){
				font-size: 14px;
			}
			@include breakpoint(medium){
				font-size: 16px;
			}
		}
		img{
			&:hover{
				cursor: pointer;
			}
		}
	}
	.info {
		h4{
			margin-bottom: 0;
		}
		.section {
			border-bottom: 1px solid #CCC;
			padding:15px 0;
		}
		span{
			text-align: right;
			transform: rotate(25deg);
			color: #49a9c4;
		}
	}
	#aboutus-overview{
		@include breakpoint(small){
			padding: 40px 0 50px;
		}

		@include breakpoint(medium) {
			padding: 120px 0 120px;
		}
	}
}