section.team-details{
	display: none;
	.back{
		font-size: 22px;
		font-family: 'Avenir',sans-serif;
		font-weight: 600;
		margin-bottom:35px;
		text-transform: uppercase;
		@include breakpoint(small){
			margin-top:28px;
		}
		a{
			&:before{
				content: '';
				border-bottom:4px solid;
				border-left:4px solid;
				transform: rotate(45deg);
				width: 15px;
				height: 15px;
				display: inline-block;
				margin-right: 20px;
				padding-top:5px;
			}
		}
	}
	img{
		padding-left:0;
	}
	.info{
		margin-top:0;
		@include breakpoint(small){
			font-size:14px
		}
		
		@include breakpoint(medium){
			margin-top: 43px;
			font-size:16px
		}		
		a.download {
			&:after {
				float: right;
				margin-right: 15px;
			}
		}
		
		.section{
			border-bottom: 1px solid #ccc;
			padding: 15px 0;
		}
		.section:first-child{
			border-top:1px solid #ccc;
		}
	}
	.lebenslauf{
		margin-top: 40px;
		span{
			color: #d2d2d2;
			&:before{
				content: '';
				vertical-align: center;
				height: 1px;
				width: 32%;
				margin-right: 20px;
				background-color: #d2d2d2;
				display: inline-block;
				margin-bottom:4px;
			}
		}
	}	
}