.scroll-top {
	text-align: center;
	padding: 42px 0 62px;
}

footer {
	background: $black;
	color: #FFF;
	padding: 25px 0 82px;
	font-family: 'Avenir', sans-serif;
	.menu li {
		a {
			padding: 0;
			line-height: 1.6;

		}
		&:after {
			content: '|';
			margin-right: 20px;
			margin-left: 20px;
		}
		&:last-of-type {
			&:after {
				content: '';
				display: none;
			}
		}
	}
	.show-for-small-only{
		.menu{
			p{
				margin-bottom: 5px;
			}
			a{
				padding-left:0;
				padding-top:0;
			}
		}
	}
}